import React from 'react';
import { Link } from 'react-router-dom';
import icindlogo from '../images/icindustrieslogo.webp'
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className='header-title'>
        <Link to="/">
          <img src={icindlogo} to="/" alt="IC Industries Logo" className='icind-header-logo' />
        </Link>
        <div className="header-links">
          <Link to="/">Home</Link>
          <Link to="/games">Games</Link>
          <Link to="/about">About</Link>
          <Link to="/sevendays">7 Days</Link>
          <Link to="/contact">Contact</Link>
      </div>
      </div>
    </header>
  );
};

export default Header;
