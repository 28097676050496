import React from 'react';
import TUATPLogo from '../images/TUATIPcoverFINAL.webp';
import UTS from '../images/UnderneathTheStars.webp';
import LastSit from '../images/TheLastSit.webp';
import BubbImg from '../images/BubbImg.webp';
import FrogPrincess from '../images/FrogPrincess.webp';
import SevenDays from '../images/SevenDays.webp';
import ProcMind from '../images/ProcMind.webp';
import MechSurvE from '../images/MechSurvivorE.webp';
import Pongers from '../images/Pongers.webp';
import OUAT from '../images/OnceUponTimePikoh.webp';
import GamesBackground from '../images/GamesBackground.png';
import ScrollToTop from './ScrollToTop';
import './Games.css';

const Games = () => {
  return (
    <div className="games-page">
      <ScrollToTop />
      <div className="games-container">
      <img src={GamesBackground} className='games-background' alt='Twice Upon a Time in Pikoh'></img>
        <h2>
          Games
        </h2>
        <p>
          These are the games we have worked on in the past, or are still working on.
        </p>
        <div className='grid-container'>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://ic-industries.itch.io/tuatip" target='_blank' rel='noreferrer'>Twice Upon a Time in Pikoh</a><h6 className='game-from'>by <a href="https://ic-industries.itch.io/" target='_blank' rel='noreferrer'>IC Industries</a></h6></h1>
            <h1 className='game-description'>The sequel of the game where you help students at your school, but this time with a couple of twists.</h1>
            <a href="https://ic-industries.itch.io/tuatip" target='_blank' rel='noreferrer'>
            <img src={TUATPLogo} alt='Twice Upon a Time in Pikoh'></img>
            </a>
            <iframe className='iframe-spotify-embed' title='TUATPSoundtrack' src="https://open.spotify.com/embed/album/4xUfDi6GgdIalC8av714T5?utm_source=generator" width="100%" height="130" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://wozzyb.itch.io/underneath-the-stars" target='_blank' rel='noreferrer'>Underneath the stars</a><h6 className='game-from'>by <a href="https://wozzyb.itch.io/" target='_blank' rel='noreferrer'>wozzyb</a>, <a href="https://cannibalisticcat.itch.io/" target='_blank' rel='noreferrer'>ExpiredMilk</a>, <a href="https://ic-industries.itch.io/" target='_blank' rel='noreferrer'>IC Industries</a>, <a href="https://ronhem.itch.io/" target='_blank' rel='noreferrer'>Ronhem</a></h6></h1>
            <h1 className='game-description'>
              Made for the ISOU (In Search Of  Unique) Game Jam, this game has you fishing in space, as you try to reach to the moon.
            </h1>
            <a href="https://wozzyb.itch.io/underneath-the-stars" target='_blank' rel='noreferrer'>
            <img src={UTS} alt='Underneath The Stars'></img>
            </a>
            <iframe className='iframe-spotify-embed' title='Underneath the Stars Spotify' src="https://open.spotify.com/embed/album/1iLQHL6Hzgff3cL2VESepJ?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://ic-industries.itch.io/ouatip" target='_blank' rel='noreferrer'>Once Upon a Time in Pikoh</a><h6 className='game-from'>by <a href="https://ic-industries.itch.io/" target='_blank' rel='noreferrer'>IC Industries</a></h6></h1>
            <h1 className='game-description'>
              Made in only 1.5 weeks, was meant as a joke game about the school 'PIKOH'. You hang out with your classmates, except everybody's turning 
              into zombies.
            </h1>
            <a href="https://ic-industries.itch.io/ouatip" target='_blank' rel='noreferrer'>
            <img src={OUAT} alt='Underneath The Stars'></img>
            </a>
            <iframe className='iframe-spotify-embed' title='UTSSoundtrack' src="https://open.spotify.com/embed/album/1iLQHL6Hzgff3cL2VESepJ?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://ic-industries.itch.io/the-last-sit" target='_blank' rel='noreferrer'>The Last Sit</a><h6 className='game-from'>by <a href="https://ic-industries.itch.io/the-last-sit" target='_blank' rel='noreferrer'>D4vidDG</a>, <a href="https://itch.io/profile/bogdans27" target='_blank' rel='noreferrer'>BogdanS</a>, <a href="https://vezy.itch.io/" target='_blank' rel='noreferrer'>Vezy</a>, <a href="https://itch.io/profile/jangotoss" target='_blank' rel='noreferrer'>Jango</a>, <a href="https://ic-industries.itch.io/" target='_blank' rel='noreferrer'>IC Industries</a></h6></h1>
            <h1 className='game-description'>
              Made for the GameDev.tv Game Jam, a guy in a wheelchair has to survive in an office filled with zombies. You move around with the recoil 
              of firing your weapons!
            </h1>
            <a href="https://ic-industries.itch.io/the-last-sit" target='_blank' rel='noreferrer'>
            <img src={LastSit} alt='Last Sit'></img>
            </a>
            <iframe title='Last Sit Spotify Album' src="https://open.spotify.com/embed/album/2y7sb7f6WrTe8QvL8Kb6F9?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://crowgamesdev.itch.io/bubb" target='_blank' rel='noreferrer'>BUBB</a><h6 className='game-from'>by <a href="https://crowgamesdev.itch.io/bubb" target='_blank' rel='noreferrer'>Crow Games</a></h6></h1>
            <h1 className='game-description'>
              Developed for SpeedJam #5 <br /> An endless underwater adventure! Dodge obstacles, ascend to the surface. One hit and you burst! How far 
              can you swim?
            </h1>
            <a href="https://crowgamesdev.itch.io/bubb" target='_blank' rel='noreferrer'>
            <img src={BubbImg} alt='Bubb Logo'></img>
            </a>
            <iframe title='Bubb Game Spotify' src="https://open.spotify.com/embed/album/0VpTod5E6KCEgYX3M6bbjd?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://hamsu-dev.itch.io/the-frog-prince" target='_blank' rel='noreferrer'>The Frog Prince</a><h6 className='game-from'>by <a href="https://hamsu-dev.itch.io/" target='_blank' rel='noreferrer'>Hamsu</a>, <a href="https://hamazon.itch.io/" target='_blank' rel='noreferrer'>Hamazon</a>, <a href="https://itch.io/profile/bogdans27" target='_blank' rel='noreferrer'>BogdanS</a>, <a href="zho" target='_blank' rel='noreferrer'>zho</a>, AJ, and <a href="https://ic-industries.itch.io/" target='_blank' rel='noreferrer'>IC Industries</a></h6></h1>
            <h1 className='game-description'>
              Made for the Godot Wild Jam #70, you play as a frog on a mission to break a curse by kissing the princess. Fight through waves of 
              knights, archers, warriors, and wardens.
            </h1>
            <a href="https://hamsu-dev.itch.io/the-frog-prince" target='_blank' rel='noreferrer'>
            <img src={FrogPrincess} alt='Frog Princess Logo'></img>
            </a>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://daverthedave.wixsite.com/ic-industries/about-1" target='_blank' rel='noreferrer'>7 Days</a><h6 className='game-from'>by <a href="https://ic-industries.itch.io/" target='_blank' rel='noreferrer'>IC Industries</a></h6></h1>
            <h1 className='game-description'>
              Still in development, the first serious game developed by IC Industries. Discover the strange world you've gotten yourself in and accept your trauma's and regrets. 
              With a hand-drawn artstyle and atmospheric music.
            </h1>
            <a href="https://daverthedave.wixsite.com/ic-industries/about-1" target='_blank' rel='noreferrer'>
            <img src={SevenDays} alt='Seven Days'></img>
            </a>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://discord.com/invite/Kph3amRZMv" target='_blank' rel='noreferrer'>Procrasination Mind</a><h6 className='game-from'>by <a href="https://discord.com/invite/Kph3amRZMv" target='_blank' rel='noreferrer'>Re Leitzia</a></h6></h1>
            <h1 className='game-description'>
              Still in development, explore the mysterious kingdom of the dead, uncover hidden secrets, and seek vengeance for your brother's murder while navigating treacherous 
              alliances and supernatural dangers.
            </h1>
            <a href="https://discord.com/invite/Kph3amRZMv" target='_blank' rel='noreferrer'>
            <img src={ProcMind} alt='Procrasination Mind'></img>
            </a>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://store.steampowered.com/app/3054480/Mech_Survivor_Elite/" target='_blank' rel='noreferrer'>Mech Survivor Elite</a><h6 className='game-from'>by <a href="https://store.steampowered.com/search/?developer=Viktor%20Borg%20Grelsson" target='_blank' rel='noreferrer'>Viktor Borg Grelsson</a></h6></h1>
            <h1 className='game-description'>Mech Survivor Elite is a time-based survival game featuring action-packed gameplay and roguelite elements.</h1>
            <a href="https://discord.com/invite/Kph3amRZMv" target='_blank' rel='noreferrer'>
            <img src={MechSurvE} alt='Mech Survivor Elite'></img>
            </a>
          </div>
          <div className='grid-games'>
            <h1 className='game-title'><a href="https://invernyako.itch.io/pogners" target='_blank' rel='noreferrer'>Pongers</a><h6 className='game-from'>by <a href="https://itch.io/profile/invernyako" target='_blank' rel='noreferrer'>InVeRnyako</a></h6></h1>
            <h1 className='game-description'>
              Made for the One Game a Month Game Jam, <br /> Pongers is turn-based PvP game where players play by using cards to place plans of figures on board, give those figures 
              material, and apply magic to figures.
            </h1>
            <a href="https://discord.com/invite/Kph3amRZMv" target='_blank' rel='noreferrer'>
            <img src={Pongers} alt='Pongers'></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games;
