import React from 'react';
import { Link } from 'react-router-dom';
import SevenDays from '../images/7DaysWIP.gif';
import GameBackground from '../images/7DaysBackground.png'
import JoinDiscord from '../images/JoinDiscord.gif'
import ScrollToTop from './ScrollToTop';
import './SevenDays.css';

const sevendays = () => {
  return (
    <div className="sevendays-page">
      <ScrollToTop />
      <div className='sevendays-container'>
        <img className='image-container' src={SevenDays} alt='Seven Days WIP'></img>
        <img className='game-background' src={GameBackground} alt='game background' />
        <h5 className='topcontainer-gamedesc'>A game by IC Industries</h5>
        <h5 className='topcontainer-gamedesc2'>
          Discover the strange world you've entered and embrace your traumas and regrets with a hand-drawn art style, atmospheric music, and a creative vision.
          <br /> <br />
          Step into a world where dreams and reality intertwine. Play as a boy caught between wakefulness and dreaming as he explores his daily memories. Each day uncovers a new 
          aspect of his life, from school and family to love and loss. Your choices will shape whether he finds hope, awakens, or descends into darkness. Immerse yourself in unique 
          environments, soothing music, and an emotional story in this captivating adventure.
        </h5>
        <div className='spotify-artist'>
        <div className='flex-container'>
          <iframe className='youtube-embed' width="1902" height="787" src="https://www.youtube.com/embed/IhwruMIFttI" title="7 Days Early Gameplay Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe className='youtube-embed' width="560" height="315" src="https://www.youtube.com/embed/z_RdkjcbUXY?si=9or9VqKlMjMLFSIo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe className="spotify-artist" title='IC Industries Spotify' src="https://open.spotify.com/embed/artist/4kUyfSyQVgBIgX9nMlFIV5?utm_source=generator" width="100%" height="500" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
        </div>
      </div>
      <div className='our-discord'>
        <a href="https://discord.gg/bAT7S5NZZ5" target='_blank' rel='noreferrer'>
          <img className='image-container' src={JoinDiscord} alt='Join our Discord'></img>
        </a>
          <Link target='_blank' rel='noreferrer' to="https://discord.gg/bAT7S5NZZ5" className="discord-button">Join Us</Link>
      </div>
    </div>
  );
};

export default sevendays;
