import React from 'react';
import icindlogo from '../images/icindustrieslogo.webp'
import ScrollToTop from './ScrollToTop';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <ScrollToTop />
          <img src={icindlogo} alt="IC Industries Logo" className='icind-about-logo' />
          <p>
          Founded by David Karapetyan, IC Industries is dedicated to composing and producing exceptional game music and sound effects. Although our current portfolio is modest, we 
          are passionate about expanding our reach and contributing to more game projects.
          <br /> <br />
          From electronic beats and calm ambient sounds to dramatic pieces, we specialize in creating a wide range of audio experiences to enhance your game's atmosphere. Let us 
          collaborate with you to bring your game's world to life with unforgettable music and sound effects.
        </p>
    </div>
  );
};

export default About;
