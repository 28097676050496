import React from 'react';
import icindlogo from '../images/icindustrieslogo.webp'
import ScrollToTop from './ScrollToTop';
import { FaDiscord } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import './Contact.css';

const About = () => {
  return (
    <div className="contact-page">
      <ScrollToTop />
          <img src={icindlogo} alt="IC Industries Logo" className='icind-about-logo' />
          <h2>Contact Us:</h2>
          <p><FaDiscord /> daverthedave</p>
          <p><FaInstagram /> <a href='https://www.instagram.com/david_aka_alexander/' target='_blank' rel='noreferrer'>david_aka_alexander</a></p>
          <p><MdOutlineMail /> <a href='mailto:daverthedave@gmail.com' target='_blank' rel='noreferrer'>daverthedave@gmail.com</a></p>
          <br />
          <h2>Site Credit:</h2>
          <p><CgWebsite /> <a href='https://tylerthedev.pages.dev' target='_blank' rel='noreferrer'>Tyler The Dev</a></p>
    </div>
  );
};

export default About;
